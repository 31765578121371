import { Link } from "gatsby";
import queryString from "query-string";
import * as React from "react";
import Button from "src/components/Button/Button";
import Seo from "src/components/Seo";
import Typography from "src/components/Typography";

import { PageProps } from "src/types";

class FailPayment extends React.Component<PageProps, any> {
  public types = {
    play_list: "/medialibrary",
    scenario_review: "/account/reviews",
    course: "/school",
  };

  public async componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    this.setState({ type: query?.type, message: query?.message });
  }

  public render() {
    return (
      <>
        <Seo pageTitle="Произошла ошибка" />
        <div
          style={{
            textAlign: "center",
          }}
        >
          <Typography type="h4"> Что-то пошло не так. </Typography>
          <br />
          <Typography type="h4">Попробуй оплатить снова.</Typography>
          <br />
          <Typography type="h5">{this.state?.message}</Typography>

            <div>
            <Link to={this.types[this.state?.type] || "/account/orders"}>
              <Button
                style={{
                  margin: "auto",
                  marginTop: "24px",
                }}
              >
                СМОТРЕТЬ
              </Button>
            </Link>
          </div>
        </div>
      </>
    );
  }
}

export default FailPayment;
